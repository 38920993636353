@media screen and (min-width:1000px) {
.searchBoxContainer {
  padding: 0px;
  background: none;
}
.searchBoxContainer:before {
  top: 12px;
  left: 12px;
}
.searchBoxContainer button.searchLinkButton {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.125rem;
}

}
