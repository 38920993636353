@media screen and (min-width:1000px) {
.contentHeader.Hero .heroContainer {
  -ms-flex-direction: row;
      flex-direction: row;
  height: 41.09vw;
  padding: 0 40px;
  background-size: 100%;
}
.contentHeader.Hero .heroContainer .contentContainer {
  width: 33.33%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  padding: 0;
}

}
