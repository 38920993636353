@media screen and (min-width:767px) {
.carouselContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
}
.carouselContainer .navigationButton {
  display: initial;
}
.carouselContainer .navigationButton.previousButton {
  -ms-flex-order: 1;
      order: 1;
}
.carouselContainer .navigationButton.nextButton {
  -ms-flex-order: 3;
      order: 3;
}
.carouselContainer .itemsWrapper {
  overflow-x: hidden;
  -ms-flex-order: 2;
      order: 2;
}

}
