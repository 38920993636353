.carouselContainer .navigationButton {
  display: none;
  -ms-flex-item-align: center;
      align-self: center;
  background-color: transparent;
  border-radius: 100%;
  padding: 5px;
  border: 2px solid #0db9d1;
}
.carouselContainer .navigationButton:after {
  display: block;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-family: 'Icon Fonts';
  content: "\e92a";
  color: #0db9d1;
}
.carouselContainer .navigationButton.hidden {
  border-color: transparent !important;
}
.carouselContainer .navigationButton.hidden:after {
  visibility: hidden;
}
.carouselContainer .navigationButton.previousButton {
  margin-right: 10px;
}
.carouselContainer .navigationButton.previousButton:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.carouselContainer .navigationButton.nextButton {
  margin-left: 10px;
}
.carouselContainer .itemsWrapper .itemsInner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-scroll-snap-type: x mandatory;
      -ms-scroll-snap-type: x mandatory;
          scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}
.carouselContainer .itemsWrapper .itemsInner::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}
.carouselContainer .itemsWrapper .itemsInner .item {
  scroll-snap-align: start;
  -ms-flex: 1 0 auto;
      flex: 1 0 auto;
}
