.contentListContainer .ratingsAndReviewCount .stars:before {
  color: #e25714;
}
.contentListContainer .ratingsAndReviewCount .ratingCount {
  color: #333333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: #666666;
  vertical-align: text-top;
}
