@media screen and (min-width:1000px) {
body {
  overflow-x: initial;
}
.mainContainer,
.pageContainer {
  max-width: 1200px;
  margin: auto;
}
.pageContainer {
  padding-left: 0;
  padding-right: 0;
}
.columns {
  white-space: nowrap;
}
.columns .primary,
.columns .secondary {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.columns .primary {
  margin: 0 10px;
  width: calc(100% - 420px);
}
.columns .secondary {
  margin-left: 20px;
  margin-right: 20px;
  width: 360px;
}
/* Temporary home page class for BC and PB - allow full width desktop items - constraint enforced within each component */
.pageHome .languageContainer,
.pageHome .mainContainer,
.pageHome .pageContainer {
  max-width: none;
}
.pageHome .pageContainer .copy,
.pageHome .pageContainer .PandoSitesScrollerList,
.pageHome .pageContainer .adChrome,
.pageHome .pageContainer .PandoSitesCategoryScrollerList .contentListItems {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.pageHome .homePage {
  padding: 0;
}
.pageHome .channelLinks.hasImages {
  margin: 10px 0 60px;
}
.pageHome .channelLinks.hasImages .channelLinksInner {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

}
