@media screen and (min-width:1000px) {
.verticalVideoList .contentContainer {
  margin: 0 auto;
  padding: 40px;
  max-width: 1200px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  min-height: 500px;
}
.verticalVideoList .contentContainer .headingContainer {
  -ms-flex: 1 0 auto;
      flex: 1 0 auto;
  text-align: left;
  max-width: calc(50% - 80px);
}
.verticalVideoList .contentContainer .carouselContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
  min-width: 600px;
  padding: 0;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper {
  width: 100%;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item {
  width: calc(33.3% - 17px);
}
.verticalVideoList .contentContainer .carouselContainer.singleVideo {
  padding: 0;
}
.verticalVideoList .contentContainer .carouselContainer.skeletonLoader .itemsWrapper {
  overflow-x: hidden;
}
.verticalVideoList .contentContainer .carouselContainer.skeletonLoader .itemsWrapper .itemsInner {
  margin: 0 44px;
}
.pageArticle .verticalVideoList .contentContainer,
.pageCollection .verticalVideoList .contentContainer {
  display: block;
}
.pageArticle .verticalVideoList .contentContainer .headingContainer,
.pageCollection .verticalVideoList .contentContainer .headingContainer {
  text-align: center;
}
.pageArticle .verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner,
.pageCollection .verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner {
  -ms-flex-pack: center;
      justify-content: center;
}

}
