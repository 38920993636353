header {
  background: #fff;
  position: relative;
}
header .headerContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: wrap;
      flex-flow: wrap;
  -ms-flex-align: center;
      align-items: center;
}
header .headerContainer .logoContainer {
  -ms-flex-order: 1;
      order: 1;
  margin: 10px 0 10px 10px;
}
header .headerContainer .userLinks {
  -ms-flex-order: 2;
      order: 2;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-pack: right;
      justify-content: right;
  margin: 10px;
}
header .headerContainer .simpleNavToggle {
  -ms-flex-order: 3;
      order: 3;
  margin: 10px 10px 10px 0;
}
header .headerContainer .headerSearch {
  -ms-flex-order: 4;
      order: 4;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
}
header .language {
  background-color: #fff;
}
header .language .languageContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  -ms-flex-align: center;
      align-items: center;
  position: relative;
  z-index: 2;
  padding: 8px;
}
header .language .languageContainer a {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: normal;
  text-decoration: underline;
  color: #333333;
}
header .language .languageContainer a:hover,
header .language .languageContainer a:focus {
  text-decoration: none;
}
