@media screen and (min-width:767px) {
.channelLinks.hasImages li {
  width: 40%;
}
.channelLinks.hasImages li a img {
  -ms-flex: 0 0 100px;
      flex: 0 0 100px;
  max-width: 100px;
}

}
