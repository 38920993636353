.simpleNavToggle {
  position: relative;
  padding: 24px 5px 5px;
  background: #f5f5f6;
  color: #000;
  border-radius: 5px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  z-index: 2;
  cursor: pointer;
  transition: opacity 0.2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.simpleNavToggle span {
  opacity: 1;
  transition: opacity 0.2s;
}
.simpleNavToggle:hover {
  text-decoration: none;
}
.simpleNavToggle:before,
.simpleNavToggle:after {
  content: '';
  background-color: #000;
  position: absolute;
  display: block;
  left: 10px;
  height: 2px;
  width: 24px;
  transition: all 0.2s;
}
.simpleNavToggle:before {
  top: 6px;
}
.simpleNavToggle:after {
  top: 14px;
}
.simpleNavToggle.opened span {
  opacity: 0;
  transition: opacity 0.1s;
}
.simpleNavToggle.opened:before {
  left: 11px;
  width: 25px;
  top: 8px;
  -webkit-transform: translate(-2px, 11px) rotate(45deg);
          transform: translate(-2px, 11px) rotate(45deg);
}
.simpleNavToggle.opened:after {
  left: 11px;
  width: 25px;
  top: 16px;
  -webkit-transform: translate(-2px, 3px) rotate(-45deg);
          transform: translate(-2px, 3px) rotate(-45deg);
}
.targetedNavigation {
  display: block;
  position: absolute;
  top: 65px;
  bottom: 0;
  overflow: visible;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  z-index: 4;
  box-shadow: 0 4px 4px -3px rgba(0, 0, 0, 0.1);
}
.targetedNavigation.opened {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}
.targetedNavigation .levelOneItems {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  gap: 10px;
  padding: 10px;
  background: #f5f5f6;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.targetedNavigation .levelOneItems li {
  position: relative;
  padding: 0 10px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.375rem;
  line-height: 2rem;
  font-family: 'Montserrat', sans-serif;
  background: #fff;
  overflow: hidden;
  border-radius: 4px;
}
.targetedNavigation .levelOneItems li a {
  display: block;
  position: relative;
  padding: 10px 0;
  color: #000;
}
.targetedNavigation .levelOneItems li a:focus {
  outline-offset: -3px;
}
.targetedNavigation .levelOneItems li.withChildren:before,
.targetedNavigation .levelOneItems li.withChildren:after {
  content: '';
  position: absolute;
  display: inline-block;
  height: 2px;
  width: 10px;
  vertical-align: middle;
  right: 15px;
  top: 22px;
  background: #e25714;
}
.targetedNavigation .levelOneItems li.withChildren:after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.targetedNavigation .levelOneItems li.withChildren.expanded {
  padding-bottom: 15px;
}
.targetedNavigation .levelOneItems li.withChildren.expanded:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.targetedNavigation .levelOneItems li.withChildren a,
.targetedNavigation .levelOneItems li.withChildren span {
  margin-right: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.targetedNavigation .levelOneItems li.withChildren .listSection {
  display: none;
}
.targetedNavigation .levelOneItems li .subNavDisplay .subNavSection {
  padding-top: 10px;
}
.targetedNavigation .levelOneItems li .subNavDisplay .levelTwoItems {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  gap: 20px;
}
.targetedNavigation .levelOneItems li .subNavDisplay .levelTwoItems li {
  margin: 0;
  padding: 0;
  background: #fff;
  color: #e25714;
  font-family: 'Montserrat', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.targetedNavigation .levelOneItems li .subNavDisplay .levelTwoItems li a {
  color: #e25714;
  margin: 0;
  padding: 0;
}
.targetedNavigation .levelOneItems li .subNavDisplay .levelTwoItems li a:after {
  border-left-width: 0;
}
.targetedNavigation .levelOneItems li .subNavDisplay .levelTwoItems li .levelThreeItems {
  max-height: 0;
}
.targetedNavigation .levelOneItems li .subNavDisplay .levelTwoItems li:before,
.targetedNavigation .levelOneItems li .subNavDisplay .levelTwoItems li:after {
  background: none;
}
.targetedNavigation .levelOneItems li .subNavDisplay .levelTwoItems li:last-child {
  border: 0;
}
.subNavDisplay {
  max-height: 0;
  transition: max-height 0.2s linear;
  z-index: 4;
}
.expanded > .subNavDisplay {
  overflow-y: hidden;
  max-height: none;
  transition: max-height 0.2s linear;
}
/* async:start */
li.newNavigationItem a.mainLink,
li.bakeOffContestItem a.mainLink {
  position: relative;
}
li.newNavigationItem a.mainLink:before,
li.bakeOffContestItem a.mainLink:before {
  content: "new!";
  position: absolute;
  top: 0;
  left: 0;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: #0db9d1;
}
.fr-CA li.newNavigationItem a.mainLink::before {
  content: "nouveau!";
}
.es-ES li.newNavigationItem a.mainLink::before {
  content: "nuevo!";
}
li.bakeOffContestItem a.mainLink::before {
  content: "It's Back!";
}
/* async:end */
