@media screen and (min-width:1000px) {
.userLinks .headerAnonymous .headerLogin li a {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.125rem;
  white-space: nowrap;
}
.userLinks .headerAuthenticated .headerMyAccount button.myAccount {
  padding: 10px 20px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.875rem;
  white-space: nowrap;
}
.userLinks .headerAuthenticated .favoritesButton {
  padding: 10px 15px;
}
.userLinks .headerAuthenticated .favoritesButton:before {
  height: 24px;
  width: 28px;
  background-size: 28px;
}
.userLinks .headerAuthenticated .favoritesButton span {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.875rem;
}
/* async:start */
.userLinks .headerAuthenticated .headerMyAccount ul.drop {
  padding-bottom: 10px;
}
.userLinks .headerAuthenticated .headerMyAccount ul.drop li a,
.userLinks .headerAuthenticated .headerMyAccount ul.drop li button {
  padding: 10px 20px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.375rem;
}
/* async:end */

}
