label {
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  display: block;
  margin-bottom: 5px;
}
.hint-text {
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1rem;
  display: block;
  color: #999;
  text-align: left;
  font-weight: normal;
  margin-bottom: 5px;
}
.inputGroup {
  margin-bottom: 20px;
}
input[type="text"],
input[type="password"],
input[type="email"] {
  color: #333333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: #666666;
  display: block;
  padding: 5px;
  background-color: #fff;
  border: solid 1px #999;
  border-radius: 0;
  box-sizing: border-box;
  margin-bottom: 20px;
  width: 100%;
}
.error input[type="text"],
.error input[type="password"],
.error input[type="email"],
input[type="text"].error,
input[type="password"].error,
input[type="email"].error,
input[type="text"].alert-error,
input[type="password"].alert-error,
input[type="email"].alert-error {
  border-width: 2px;
  border-color: #db0038;
  padding: 4px;
}
.error input[type="text"]:focus,
.error input[type="password"]:focus,
.error input[type="email"]:focus,
input[type="text"].error:focus,
input[type="password"].error:focus,
input[type="email"].error:focus,
input[type="text"].alert-error:focus,
input[type="password"].alert-error:focus,
input[type="email"].alert-error:focus {
  outline-width: 0;
}
input[type="number"] {
  color: #333333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: #666666;
  display: block;
  padding: 5px;
  background-color: #fff;
  border: solid 1px #999;
  border-radius: 0;
  box-sizing: border-box;
  margin-bottom: 20px;
  width: 100%;
  display: inline-block;
  width: auto;
}
.error input[type="number"],
input[type="number"].error,
input[type="number"].alert-error {
  border-width: 2px;
  border-color: #db0038;
  padding: 4px;
}
.error input[type="number"]:focus,
input[type="number"].error:focus,
input[type="number"].alert-error:focus {
  outline-width: 0;
}
input:invalid,
input:required {
  box-shadow: none;
}
input:disabled {
  background-color: #eee;
}
input::-webkit-input-placeholder {
  color: #aaa;
}
input:-ms-input-placeholder {
  color: #aaa;
}
input::placeholder {
  color: #aaa;
}
button,
input[type="button"],
input[type="submit"] {
  border: 0;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 10px 25px;
  color: #333333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  background: #e25714;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  vertical-align: middle;
}
button:hover,
input[type="button"]:hover,
input[type="submit"]:hover {
  text-decoration: none;
}
button a,
input[type="button"] a,
input[type="submit"] a {
  color: #fff;
}
button:disabled,
input[type="button"]:disabled,
input[type="submit"]:disabled {
  opacity: 0.5;
  cursor: default;
}
button:focus,
input[type="button"]:focus,
input[type="submit"]:focus,
input[type="image"]:focus {
  outline: none;
}
textarea {
  color: #333333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: #666666;
  display: block;
  padding: 5px;
  background-color: #fff;
  border: solid 1px #999;
  border-radius: 0;
  box-sizing: border-box;
  margin-bottom: 20px;
  width: 100%;
}
.error textarea,
textarea.error,
textarea.alert-error {
  border-width: 2px;
  border-color: #db0038;
  padding: 4px;
}
.error textarea:focus,
textarea.error:focus,
textarea.alert-error:focus {
  outline-width: 0;
}
select {
  color: #333333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: #666666;
  display: block;
  padding: 5px;
  background-color: #fff;
  border: solid 1px #999;
  border-radius: 0;
  box-sizing: border-box;
  margin-bottom: 20px;
  width: 100%;
  height: 30px;
}
.error select,
select.error,
select.alert-error {
  border-width: 2px;
  border-color: #db0038;
  padding: 4px;
}
.error select:focus,
select.error:focus,
select.alert-error:focus {
  outline-width: 0;
}
input[type="checkbox"] + label,
input[type="radio"] + label {
  display: inline-block;
  vertical-align: middle;
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
}
/* Focus Indicators */
button:focus,
input[type="button"]:focus,
input[type="submit"]:focus,
a:focus {
  outline: 3px dotted #0db9d1;
  outline-offset: 3px;
  border-radius: 3px;
}
input:focus,
input[type="text"]:focus,
textarea:focus {
  outline: 2px solid #0db9d1;
  border: 1px solid transparent;
}
