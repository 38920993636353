.contentListContainer .categoryTile {
  padding: 32px 16px;
}
.contentListContainer .categoryTile .headingContainer {
  margin-bottom: 12px;
}
.contentListContainer .categoryTile .headingContainer h2 {
  margin-bottom: 8px;
  text-align: inherit;
}
.contentListContainer .categoryTile .headingContainer .descriptionContainer {
  margin-bottom: 0;
}
.contentListContainer .categoryTile .tileContainer .contentItemsWrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.contentListContainer .categoryTile .tileContainer .contentItemsWrapper .contentItem {
  margin: 12px;
}
.contentListContainer .categoryTile .tileContainer .contentItemsWrapper .contentItem .itemLink {
  display: block;
}
.contentListContainer .categoryTile .tileContainer .contentItemsWrapper .contentItem .itemLink .itemImage {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 8px;
  aspect-ratio: 1;
  object-fit: cover;
}
.contentListContainer .categoryTile .tileContainer .contentItemsWrapper .contentItem .itemLink .itemTitle {
  text-align: center;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.1875rem;
  font-weight: bold;
}
.contentListContainer .categoryTile.carousel {
  padding-right: 0;
}
.contentListContainer .categoryTile.carousel .tileContainer .contentItemsWrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-scroll-snap-type: x mandatory;
      -ms-scroll-snap-type: x mandatory;
          scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  -ms-flex-pack: unset;
      justify-content: unset;
}
.contentListContainer .categoryTile.carousel .tileContainer .contentItemsWrapper::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}
.contentListContainer .categoryTile.carousel .tileContainer .contentItemsWrapper .contentItem {
  scroll-snap-align: start;
  -ms-flex: 1 0 auto;
      flex: 1 0 auto;
}
