.userLinks {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  color: #0db9d1;
  z-index: 5;
}
.userLinks a,
.userLinks input {
  color: #0db9d1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.userLinks .headerLogin a,
.userLinks .headerMyAccount a,
.userLinks .headerLogin input,
.userLinks .headerMyAccount input {
  display: inline-block;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.userLinks .headerLogin a:hover,
.userLinks .headerMyAccount a:hover,
.userLinks .headerLogin input:hover,
.userLinks .headerMyAccount input:hover,
.userLinks .headerLogin a:focus,
.userLinks .headerMyAccount a:focus,
.userLinks .headerLogin input:focus,
.userLinks .headerMyAccount input:focus {
  text-decoration: none;
}
.userLinks .headerAnonymous .headerLogin {
  display: -ms-flexbox;
  display: flex;
}
.userLinks .headerAnonymous .headerLogin li {
  margin-left: 10px;
}
.userLinks .headerAnonymous .headerLogin li a {
  padding: 9px 10px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: 600;
  border: 2px solid #e25714;
  border-radius: 6px;
}
.userLinks .headerAnonymous .headerLogin li a.signUpButton {
  background-color: #e25714;
  color: #fff;
}
.userLinks .headerAnonymous .headerLogin li a.logInButton {
  border: 2px solid #e25714;
  background-color: transparent;
  color: #e25714;
}
.userLinks .headerAuthenticated {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  gap: 10px;
}
.userLinks .headerAuthenticated .headerMyAccount {
  position: relative;
}
.userLinks .headerAuthenticated .headerMyAccount button.myAccount {
  all: inherit;
  display: inline-block;
  padding: 7px 15px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1.875rem;
  font-weight: 600;
  color: #333333;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}
.userLinks .headerAuthenticated .headerMyAccount button.myAccount:after {
  display: inline-block;
  font-size: 8px;
  vertical-align: middle;
  margin-left: 5px;
  padding-top: 2px;
  font-weight: 600;
  transition-duration: 0.2s;
  font-family: 'Icon Fonts';
  content: "\e92b";
}
.userLinks .headerAuthenticated .headerMyAccount button.myAccount:focus {
  outline: 3px dotted #0db9d1;
  outline-offset: 3px;
  border-radius: 3px;
}
.userLinks .headerAuthenticated .headerMyAccount ul.drop {
  display: none;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
}
.userLinks .headerAuthenticated .favoritesButton {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 100px;
}
.userLinks .headerAuthenticated .favoritesButton:before {
  display: block;
  content: '';
  height: 17px;
  width: 20px;
  background: url('/Styles/Base/Images/favorites-icon.svg') center / 20px no-repeat;
}
.userLinks .headerAuthenticated .favoritesButton span {
  margin-left: 6px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1.875rem;
  font-weight: 600;
  color: #333333;
}
.userLinks .headerAuthenticated .favoritesButton:hover,
.userLinks .headerAuthenticated .favoritesButton:focus {
  text-decoration: none;
}
/* async:start */
.userLinks .headerAuthenticated .headerMyAccount ul.drop {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  background: #fff;
  padding-bottom: 7px;
  border-radius: 0 0 4px 4px;
  z-index: 2;
}
.userLinks .headerAuthenticated .headerMyAccount ul.drop li a,
.userLinks .headerAuthenticated .headerMyAccount ul.drop li button {
  display: block;
  padding: 7px 15px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1.375rem;
  font-weight: 600;
  color: #333333;
}
.userLinks .headerAuthenticated .headerMyAccount ul.drop li #logout {
  background: none;
  border-radius: 0;
  width: -webkit-fill-available;
  text-align: left;
  color: #333333;
}
.userLinks .headerAuthenticated .headerMyAccount.expanded {
  background: #fff;
  border-radius: 4px 4px 0 0;
}
.userLinks .headerAuthenticated .headerMyAccount.expanded ul.drop {
  display: block;
}
.userLinks .headerAuthenticated .headerMyAccount.expanded button.myAccount {
  color: #333333;
}
.userLinks .headerAuthenticated .headerMyAccount.expanded button.myAccount:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  transition-duration: 0.2s;
  padding-top: 2px;
  color: #333333;
}
/* async:end */
