.contentListContainer .carouselList {
  padding: 24px 0 14px;
}
.contentListContainer .carouselList .headingContainer {
  margin-bottom: 10px;
  padding: 0 20px;
}
.contentListContainer .carouselList .headingContainer h2 {
  margin-bottom: 8px;
  text-align: inherit;
}
.contentListContainer .carouselList .headingContainer .descriptionContainer {
  margin-bottom: 0;
}
.contentListContainer .carouselList .carouselContainer {
  padding-left: 20px;
}
.contentListContainer .carouselList .carouselContainer .itemsWrapper .itemsInner .item {
  max-width: 250px;
  margin: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.contentListContainer .carouselList .carouselContainer .itemsWrapper .itemsInner .item .itemLink {
  display: block;
  margin-bottom: 24px;
}
.contentListContainer .carouselList .carouselContainer .itemsWrapper .itemsInner .item .itemLink .itemImage {
  position: relative;
  width: auto;
  margin-bottom: 16px;
}
.contentListContainer .carouselList .carouselContainer .itemsWrapper .itemsInner .item .itemLink .itemImage img {
  width: 100%;
}
.contentListContainer .carouselList .carouselContainer .itemsWrapper .itemsInner .item .itemLink .itemImage .itemTag {
  position: absolute;
  top: 10px;
  left: 10px;
  max-width: calc(100% - 40px);
  padding: 5px 10px;
  background-color: white;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1rem;
  font-weight: bold;
  color: #333333;
  border-radius: 100px;
}
.contentListContainer .carouselList .carouselContainer .itemsWrapper .itemsInner .item .itemLink .itemInfo .itemTitle {
  color: #0db9d1;
  font-weight: bold;
  margin-bottom: 8px;
}
.contentListContainer .carouselList .carouselContainer .itemsWrapper .itemsInner .item .itemLink:hover {
  text-decoration: none;
}
.contentListContainer .carouselList .carouselContainer .itemsWrapper .itemsInner .item .itemLink:hover .itemInfo .itemTitle {
  text-decoration: underline;
}
