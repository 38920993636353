@media screen and (min-width:767px) {
.contentListContainer .carouselList {
  padding: 40px 20px 30px;
}
.contentListContainer .carouselList .headingContainer {
  padding: 0;
}
.contentListContainer .carouselList .carouselContainer {
  padding: 0;
}
.contentListContainer .carouselList .carouselContainer .itemsWrapper .itemsInner .item {
  width: calc(33.3% - 13px);
  max-width: 285px;
}

}
