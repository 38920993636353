@media screen and (min-width:767px) {
.featuredContent.includeMargins {
  margin: 30px auto;
}
.featuredContent .imageContainer picture {
  width: 100%;
}
.featuredContent .imageContainer picture img {
  width: 100%;
  object-fit: contain;
}

}
