.cgcContentList {
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  text-align: left;
}
.cgcContentList .contentListSort {
  display: inline-block;
  width: 49%;
  position: relative;
  vertical-align: bottom;
  margin: 20px 0;
}
.cgcContentList .contentListSort .contentListSortLabel {
  display: block;
  margin-bottom: 6px;
}
.cgcContentList .contentListSort .contentListDropdown > .contentListInnerButton {
  all: inherit;
  width: 100%;
}
.cgcContentList .contentListSort .contentListDropdownButton {
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: capitalize;
  text-decoration: none;
  font-weight: normal;
  padding: 10px 6px;
  border: solid 1px #d5cece;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  background-color: #fbfbfb;
}
.cgcContentList .contentListSort .contentListDropdownButton:hover {
  cursor: pointer;
}
.cgcContentList .contentListSort .contentListDropdownButton:after {
  font-family: 'Icon Fonts';
  content: "\e91e";
  font-weight: bold;
  font-size: 14px;
  border-left: 1px dotted;
  border-right: none;
  padding: 1px 1px 0 7px;
  width: 14px;
  height: 15px;
}
.cgcContentList .contentListSort .contentListDropdownContainer {
  display: none;
  list-style-type: none;
  margin: -1px 0 0;
  padding: 0;
  z-index: 3;
  background-color: #eee;
  border-top: 0;
  width: 100%;
  border: 1px solid #d5cece;
  box-sizing: border-box;
}
.cgcContentList .contentListSort .contentListDropdownContainer .contentListDropdownItem {
  all: inherit;
  cursor: pointer;
  padding: 10px 6px;
  width: calc(100% - 12px);
  border-bottom: solid 1px #d5cece;
}
.cgcContentList .contentListSort .contentListDropdownContainer .contentListDropdownItem:hover,
.cgcContentList .contentListSort .contentListDropdownContainer .contentListDropdownItem:focus {
  background-color: #f6f6f6;
}
.cgcContentList .contentListSort.expanded .contentListDropdownButton:after,
.cgcContentList .contentListSort .expanded .contentListDropdownButton:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  border-left: none;
  border-right: 1px dotted;
  padding: 0 7px 1px 1px;
}
.cgcContentList .contentListSort.expanded .contentListDropdownContainer,
.cgcContentList .contentListSort .expanded .contentListDropdownContainer {
  display: block;
  position: absolute;
}
.cgcContentList .contentListSort.expanded .contentListDropdownContainer li.selected,
.cgcContentList .contentListSort .expanded .contentListDropdownContainer li.selected {
  font-weight: bold;
}
.cgcContentList .contentListSort.expanded .contentListDropdownContainer li .contentListDropdownItem,
.cgcContentList .contentListSort .expanded .contentListDropdownContainer li .contentListDropdownItem {
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1rem;
  display: block;
}
.cgcContentList .cgcSkeleton {
  position: relative;
}
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcParent {
  padding-bottom: 10px;
}
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcChild {
  width: 50%;
  float: left;
}
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcUserScreenName,
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcDate,
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcRating,
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcContentText,
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcMostHelpful {
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
}
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcDate,
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcRating,
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcMostHelpful {
  width: 100px;
}
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcUserScreenName,
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcDate,
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcContentText {
  margin: 10px 0px;
}
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcUserScreenName,
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcDate,
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcRating,
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcMostHelpful {
  height: 15px;
}
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcUserScreenName {
  width: 200px;
}
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcRating {
  float: left;
}
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcContentText {
  width: 100%;
  height: 60px;
}
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcMostHelpful {
  float: right;
}
.cgcContentList .showMoreButtonContainer {
  text-align: center;
}
.cgcContentList .cgcRatingsOnlyMessage,
.cgcContentList .showMoreButtonContainer,
.cgcContentList .cgcLoading {
  margin-top: 20px;
}
