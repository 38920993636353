@font-face {
  font-family: 'Montserrat';
  src: url('/Mvc/Styles/FontFiles/Montserrat-Regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'Montserrat';
  src: url('/Mvc/Styles/FontFiles/Montserrat-Bold.woff') format('woff');
  font-style: normal;
  font-weight: 700;
}
