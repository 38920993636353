@media screen and (min-width:1000px) {
.channelLinks {
  text-align: center;
  margin: -20px auto 0;
}
.channelLinks .channelLinksInner {
  -ms-flex-pack: center;
      justify-content: center;
}
.channelLinks h3 {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
}
.channelLinks ul {
  overflow-x: hidden;
}
.channelLinks li:last-child {
  padding-right: 5px;
}
.channelLinks:after {
  background-image: none;
}
.channelLinks.hasImages h3 {
  padding: 0 25px;
  width: auto;
}
.channelLinks.hasImages li {
  width: calc(25% - 15px);
}
.channelLinks.hasImages li a .linkTitle {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.125rem;
}
.recipeDetail .channelLinks {
  padding-top: 10px;
  margin-bottom: 0;
}
/* async:start */
.socialLinks .channelLinks h3 {
  margin: 18px 0;
}
.socialLinks .channelLinks li {
  padding: 0 15px;
}
.socialLinks .channelLinks li a {
  padding: 5px;
}
.socialLinks .channelLinks li a:before {
  font-size: 2rem;
  line-height: 50px;
}
/* async:end */

}
