html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  scroll-padding-top: 20px;
}
html,
body {
  overflow-x: hidden;
}
body {
  -webkit-text-size-adjust: none;
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
}
iframe {
  display: block;
  margin: auto;
}
header,
footer,
nav,
main {
  width: 100%;
  margin: auto;
}
a {
  color: #0db9d1;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}
a:hover,
a:focus {
  text-decoration: underline;
}
p a {
  text-decoration: underline;
  font-weight: 600;
}
p a:hover,
p a:focus {
  text-decoration: none;
}
p {
  margin-bottom: 20px;
}
strong,
b {
  font-weight: bold;
}
em,
i {
  font-style: italic;
}
u {
  text-decoration: underline;
}
table {
  margin-bottom: 20px;
  display: block;
  overflow-x: auto;
}
th {
  background-color: #f2f2f2;
  font-weight: bold;
}
th,
td {
  text-align: left;
  padding: 4px 10px;
  border: 1px solid #ccc;
  min-width: 70px;
}
hr.linedDivider {
  overflow: visible;
}
.pageContainer {
  box-sizing: border-box;
  padding: 30px 10px 0;
  width: 100%;
}
.mainContainer {
  position: relative;
}
.ui-front {
  z-index: 100;
}
.expand-desc,
.collapse-desc {
  color: #0db9d1;
  background-color: transparent;
  padding: 0;
  margin: 0;
  font-weight: bold;
  white-space: nowrap;
  display: none;
}
.expand-desc:hover:enabled,
.collapse-desc:hover:enabled {
  text-decoration: underline;
}
@font-face {
  font-family: 'Icon Fonts';
  src: url('/Styles/Base/Fonts/icon-fonts-v26.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
[v-cloak] {
  display: none;
}
.lazyImageContainer {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
}
.lazyImageContainer img.lazyImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@-webkit-keyframes graypulse {
  0% {
    background-color: #ddd;
  }
  50% {
    background-color: #eee;
  }
  100% {
    background-color: #ddd;
  }
}
@keyframes graypulse {
  0% {
    background-color: #ddd;
  }
  50% {
    background-color: #eee;
  }
  100% {
    background-color: #ddd;
  }
}
.slide-enter-active {
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
}
.slide-leave-active {
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}
.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
/* Temporary home page class for BC and PB */
.pageHome .pageContainer {
  padding: 0;
}
.pageHome .channelLinks.hasImages {
  margin: 10px 0 20px;
  border-bottom: solid 2px #f1f1f1;
}
.pageHome hr.linedDivider {
  display: none;
}
.adhesiveAdSpacing {
  display: none;
  height: 90px;
}
.adhesiveAdSpacing.visible {
  display: block;
}
.adhesiveAd .closeAdButton {
  display: none;
  background: unset;
  padding: unset;
}
