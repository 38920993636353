@media screen and (min-width:1000px) {
html {
  scroll-padding-top: 120px;
}
.unsetScrollPadding {
  scroll-padding-top: unset;
}
header {
  box-shadow: 0 4px 4px -3px rgba(0, 0, 0, 0.1);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  max-width: 100%;
  z-index: 3;
}
header .headerContainer {
  max-width: 100%;
  padding: 10px 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: nowrap;
      flex-flow: nowrap;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
header .headerContainer .logoContainer {
  margin: 0;
  margin-right: 10px;
  -ms-flex: 1 1 0px;
      flex: 1 1 0px;
}
header .headerContainer .headerSearch {
  -ms-flex-order: 2;
      order: 2;
  max-width: 725px;
}
header .headerContainer .userLinks {
  -ms-flex-order: 2;
      order: 2;
  -ms-flex: 1 1 0px;
      flex: 1 1 0px;
  margin: 10px 0 10px 10px;
}
header .language .languageContainer {
  padding: 10px 20px;
}
.adhesiveHeaderAdFixed header {
  top: 130px;
}

}
