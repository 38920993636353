@media screen and (min-width:767px) {
.contentListContainer .categoryTile .headingContainer {
  max-width: 1200px;
  margin: 0 auto 12px;
}
.contentListContainer .categoryTile .tileContainer .contentItemsWrapper .contentItem .itemLink .itemImage {
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.contentListContainer .categoryTile .tileContainer .contentItemsWrapper .contentItem .itemLink .itemImage:hover {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}
.contentListContainer .categoryTile .tileContainer .contentItemsWrapper .contentItem .itemLink:focus .itemImage {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}
.contentListContainer .categoryTile.carousel {
  padding-right: 16px;
}
.contentListContainer .categoryTile.carousel .tileContainer .contentItemsWrapper {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: center;
      justify-content: center;
  overflow-x: unset;
}
.contentListContainer .categoryTile.carousel .tileContainer .contentItemsWrapper .contentItem {
  -ms-flex: unset;
      flex: unset;
}

}
