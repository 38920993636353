@media screen and (min-width:767px) {
.cgcRedesignContainer .contentListContainer {
  max-width: 1200px;
  margin: 0 auto;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection {
  -ms-flex-direction: row;
      flex-direction: row;
  padding: 32px;
  gap: 32px;
  margin-bottom: 32px;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .reviewSummaryDisplay {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 1 0 240px;
      flex: 1 0 240px;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .reviewSummaryDisplay .iconAiSummary {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 4rem;
  line-height: 4rem;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .aiSummaryDivider {
  width: 1px;
  height: unset;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  margin: 0px;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .aiSummaryBodyText {
  -ms-flex: 0 1 auto;
      flex: 0 1 auto;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.375rem;
  -ms-flex-line-pack: center;
      align-content: center;
}
.cgcRedesignContainer .contentListContainer .contentListHeader {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-align: center;
      align-items: center;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListCount {
  margin-bottom: 0;
}
.cgcRedesignContainer .contentListContainer .emptyListContainer {
  margin-top: 32px;
  padding: 32px;
}
.cgcRedesignContainer .contentListContainer .showMoreButtonContainer {
  margin-top: 32px;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardReviewHeader .reviewTitle {
  width: 75%;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardReviewHeader .reviewRating {
  width: 15%;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardBody .contentText {
  height: 40px;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardBody .contentCardBodyFields .contentUserInfo {
  width: 25%;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardFooter .contentActions {
  width: 20%;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardFooter .contentFeedback {
  width: 20%;
}

}
