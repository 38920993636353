.skeletonContainer .headerSkeleton {
  width: 300px;
  height: 32px;
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
  margin-bottom: 8px;
}
.skeletonContainer .descriptionSkeleton {
  width: 75%;
  min-width: 300px;
  height: 24px;
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
  margin-bottom: 10px;
}
.skeletonContainer .contentItemsWrapper {
  display: -ms-flexbox;
  display: flex;
}
.skeletonContainer .contentItemsWrapper .contentItem {
  margin: 12px;
}
.skeletonContainer .contentItemsWrapper .contentItem .itemImage {
  width: auto;
  aspect-ratio: 1.77777778;
  margin-bottom: 16px;
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
}
.skeletonContainer .contentItemsWrapper .contentItem .itemInfo {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin-bottom: 24px;
}
.skeletonContainer .contentItemsWrapper .contentItem .itemInfo .itemTitle {
  width: 75%;
  height: 22px;
  margin-bottom: 8px;
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
}
.skeletonContainer .contentItemsWrapper .contentItem .itemInfo .ratingsAndReviewCount {
  width: 120px;
  height: 16px;
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
}
.skeletonContainer .contentItemsWrapper .contentItem .favoriteButton {
  width: 60px;
  height: 18px;
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
}
.skeletonContainer.carouselList {
  padding: 24px 20px 14px;
}
.skeletonContainer.categoryTile {
  padding: 32px 16px;
}
.skeletonContainer.categoryTile .contentItemsWrapper {
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.skeletonContainer.categoryTile .contentItemsWrapper .contentItem .itemImage {
  aspect-ratio: 1;
  border-radius: 50%;
}
.skeletonContainer.categoryTile .contentItemsWrapper .contentItem .itemInfo {
  -ms-flex-align: center;
      -ms-grid-row-align: center;
      align-items: center;
}
