@media screen and (min-width:767px) {
table {
  display: table;
}
th,
td {
  padding: 4px;
  min-width: 0;
}
.pageContainer {
  padding-left: 20px;
  padding-right: 20px;
}
.mainContainer {
  margin-bottom: 40px;
}
.oneColumn {
  max-width: 748px;
  margin: auto;
}
.adhesiveAdSpacing {
  height: 130px;
}

}
