@media screen and (min-width:1000px) {
.contentListContainer .carouselList .headingContainer {
  max-width: 1200px;
  margin: 0 auto 10px;
}
.contentListContainer .carouselList .carouselContainer .itemsWrapper {
  max-width: 1200px;
}
.contentListContainer .carouselList .carouselContainer .itemsWrapper .itemsInner .item {
  width: calc(25% - 15px);
}
.contentListContainer .carouselList.inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
.contentListContainer .carouselList.inline .headingContainer {
  margin: 0 20px 0 44px;
  width: 25%;
  max-width: 236px;
}
.contentListContainer .carouselList.inline .carouselContainer {
  -ms-flex: 0 1 auto;
      flex: 0 1 auto;
  max-width: 988px;
  width: 75%;
}
.contentListContainer .carouselList.inline .carouselContainer .itemsWrapper {
  max-width: 900px;
}
.contentListContainer .carouselList.inline .carouselContainer .itemsWrapper .itemsInner .item {
  width: calc(33.3% - 15px);
  max-width: 285px;
}

}
